import { AppID } from '@playq/octopus-common';

import { Brief } from '../types';

export const getEnabledOrPrefetchStatus = (optionsEnabled: boolean | undefined, appID: AppID | undefined): boolean => {
  return (optionsEnabled ?? true) && appID !== undefined;
};

export const getRowKey = (item: Brief): string => {
  return item.id.serialize();
};
export const splitAtFirstUppercase = (name: string) => name.replace(/([a-z])([A-Z])/, '$1 $2');

export const isJsonOrJsonZip = (filename: string): boolean => {
  const lowerName = filename.toLowerCase();
  return lowerName.endsWith('.json') || lowerName.endsWith('.json.zip');
};
