import { ZipReader, BlobReader, TextWriter } from '@zip.js/zip.js';

export const fetchAndExtractZip = async ({
  url,
  name,
  cb,
}: {
  url: string;
  name: string;
  cb: (prop: { text: string; name: string }) => void;
}) => {
  try {
    cb({ text: 'Loading...', name: name });
    const response = await fetch(url, {
      method: 'GET',
      cache: 'no-cache',
      credentials: 'same-origin',
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch the file: ${response.status} ${response.statusText}`);
    }

    if (name.endsWith('.json')) {
      try {
        const json = (await response.json()) as Response['json'];

        const data = JSON.stringify(json, null, 2);
        cb({ text: data, name });
        return;
      } catch (jsonError) {
        console.error('Error parsing JSON:', jsonError);
        cb({ text: `Error parsing JSON: ${(jsonError as Error)?.message}`, name });
        return;
      }
    }

    const blob: Blob = await response.blob();
    const reader: ZipReader<Blob> = new ZipReader(new BlobReader(blob));
    const entries = await reader.getEntries();

    for (const entry of entries) {
      if (!entry.directory) {
        if (entry.getData) {
          const text = await entry.getData(new TextWriter());
          cb({ text, name });
        }
      }
    }

    reader.close();
  } catch (error) {
    cb({ text: `Error extracting ZIP: ${(error as Error).message}`, name });
    console.error('Error extracting ZIP:', error);
  }
};
